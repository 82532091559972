$primaryFont: sans-serif;

$cfaPrimary: #004F71;
$cfaPrimaryFade: #004F7159;
$cfaPrimary500: #Fa9370;

$cfaLightGray: #F5F5F5;
$cfaDarkGray: #5B6770;

$cfaErrorRed: #DD0031;
$cfaWhiteBackground: #F4F8F9;
$cfaWhite: #FFFFFF;
$cfaBlackFade: rgba(3, 3, 3, 0.15);
$cfaBackground: #f4f8f9;
$cfaBlack: #000000;
$cfaGray: #8E969C;
$cfaPattensBlue: #DBE0E2;
$cfaFaintGray: #65737C;
