@import 'styles/_variables.scss';

/* You can add global styles to this file, and also import other style files */
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: $cfaPrimary;
}

.mat-progress-bar-fill::after {
  background-color: #bdbdbd;
}

.mat-progress-bar-buffer {
  background: #eeeeee;
}

.mat-simple-snackbar-action button {
  font-family: $primaryFont;
}

.mat-simple-snackbar {
  font-family: $primaryFont;
}

.mat-simple-snackbar-action {
  color: $cfaWhite;
}

.mat-input-element:disabled {
  color: $cfaPrimary;
}

.mat-select-value-text {
  color: $cfaPrimary;
}

.mat-select-disabled .mat-select-arrow {
  visibility: hidden;
}

.mat-card .mat-button .mat-input-element {
  font-family: $primaryFont;
}

.mat-button .mat-button[disabled] .mat-raised-button .mat-raised-button[disabled] {
  font-family: $primaryFont;
  color: $cfaPrimary;
}


.mat-input-element {
  caret-color: $cfaPrimary;
}

.mat-calendar-body-selected {
  background-color: $cfaPrimary;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $cfaPrimary;
  font-family: $primaryFont;
}

.mat-select .mat-option {
  font-family: $primaryFont;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $cfaPrimary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $cfaPrimary;
  background-color: $cfaPrimary;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $cfaPrimaryFade;
}

.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $cfaPrimaryFade !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $cfaPrimary !important;
  }
}

@import '~bootstrap/scss/bootstrap.scss';

//@import "~angular-material/angular-material.css";
//@import '~@angular/material/prebuilt-themes/indigo-pink.css';
//@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
//@import '~@angular/material/prebuilt-themes/pink-bluegrey.css';
//@import '~@angular/material/prebuilt-themes/purple-green.css';

//html, body { height: 100%; }
